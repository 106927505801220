const GET = 'GET';
const POST = 'POST';

function checkStatus(response)
{
  if (response.status >= 200 && response.status < 300)
  {
    return Promise.resolve(response);
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(JSON.stringify(error));

  return Promise.reject(error);
}

function parseJSON(response)
{
  return response.json();
}

/*
function getText(response)
{
  return response.text();
}
*/

function fetchJson(method, url, json, cb)
{
  const text = json ? JSON.stringify(json) : undefined;
  const token = getToken();
  const headers = new Headers({ 'Accept': 'application/json', 'Accept-Charset': 'utf-8' });
  if (text)
  {
    headers.append('Content-Type', 'application/json;charset=utf-8');
    headers.append('Content-Length', text.length.toString());
  }
  if (token)
  {
    headers.append('Authorization', `Token ${token}`);
  }
  const options = {
    method: method,
    credentials: 'same-origin',
    mode: 'same-origin',
    cache: 'no-cache',
    headers: headers,
    body: text,
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(cb);
}

export function postLogin(cb)
{
  return fetchJson(POST, '/api/login', null, cb);
}

export function postUserRegistration(displayName, cb)
{
  return fetchJson(POST, '/api/register', { displayName: displayName }, cb);
}

export function getMyProfile(cb)
{
  return fetchJson(GET, '/api/profile', null, cb);
}

export function getMyGroups(cb)
{
  return fetchJson(GET, '/api/grouplist', null, cb);
}

export function getToken()
{
  try
  {
    return window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token;
  }
  catch (e)
  {
    return '';
  }
}

const Client = { postLogin, postUserRegistration, getMyProfile, getMyGroups, getToken };
export default Client;

